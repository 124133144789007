<template>
  <div>
    <Breadcrumbs
      page_title="favourite"
      :items="breadcrumbs"
      v-show="!isshowarticleView"
    />

    <v-card
      class="mx-3 my-5 rounded-lg"
      v-show="!isshowarticleView"
      v-if="hasData"
    >
      <template>
        <v-container fluid>
          <v-row class="d-flex">
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              lg="6"
              md="6"
              sm="12"
              class="border"
            >
              <v-list>
                <v-list-item-group>
                  <v-list-item class="pa-0" @click="goarticleDetail(item)">
                    <v-col>
                      <v-img :src="item.optimizeLink" class="lessonimg"></v-img>
                    </v-col>
                    <v-col cols="12" md="8" lg="6">
                      <v-list-item-content style="color: #424242">
                        <v-list-item-title style="font-size: 18px">
                          {{ item.content_Name }}
                        </v-list-item-title>

                        <v-list-item-subtitle class="mt-3">
                          {{ item.wordDescription }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="item.contentCheck === 1 || item.contentCheck === 4"
                    >
                      <div style="width: 35px; height: 35px" class="lessonIcon">
                        <v-img
                          src="@/assets/images/article_home.svg"
                          width="30"
                          height="35"
                          class="mx-auto my-auto center"
                        />
                      </div>
                    </v-col>
                    <v-col cols="2" v-else>
                      <v-progress-circular
                        :rotate="-90"
                        :size="35"
                        :width="3"
                        :value="item.progressTime * 100"
                        color="#4FB14E"
                        class="lessonIcon"
                      >
                        <v-icon
                          style="
                            font-size: 20px;
                            color: #cccccc;
                            padding-left: 3px;
                            padding-top: 2px;
                          "
                          >mdi-play-outline</v-icon
                        >
                      </v-progress-circular>
                    </v-col>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <div class="test">
                <div class="border1"></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-card>

    <v-card
      class="loadingarticle mx-3 my-5 rounded-lg d-flex justify-center"
      v-else-if="loading"
    >
      <v-row class="mx-3 my-5">
        <v-col cols="6" v-for="n in 2" :key="n">
          <v-skeleton-loader
            class="mx-auto"
            max-height="180"
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="noarticle mx-3 my-5 rounded-lg d-flex justify-center" v-else>
      <div class="text-center nodata">{{ $t("nofavourite") }}</div>
    </v-card>

    <soundPlay
      ref="soundPlay"
      :show="showSound"
      @close="(showSound = false), getFavourite()"
      @show_sound="show_sound"
      :audioName="audioName"
      :audioDescription="audioDescription"
      :dialog="dialog"
      :audioFile="contentFile"
      :audioImg="audioImg"
      :viewerFlag="viewerFlag"
      :contentid="contentId"
      :currentminutes="currentminutes"
    ></soundPlay>

    <videoPlay
      ref="videoPlay"
      :show="showVideoDialog"
      @close="(showVideoDialog = false), getFavourite()"
      :videoName="videoName"
      :videoPlayerDialog="videoPlayerDialog"
      :videoFile="video_File"
      :contentid="contentId"
      :courseID="courseID"
      :lastWatchedTime="lastWatchedTime"
      :viewerFlag="viewerFlag"
      :memberID="memberID"
      :video_page="1"
    ></videoPlay>
  </div>
</template>

<script>
import axios from "axios";
import soundPlay from "../Library/_subLibrary/_path/_sound/soundPlay.vue";
import videoPlay from "../Library/_subLibrary/_path/_video/videoPlay.vue";

export default {
  props: {},
  components: {
    soundPlay,
    videoPlay,
  },
  data() {
    return {
      currentminutes: "",
      userID: "",
      companyID: "",
      breadcrumbs: [
        // {
        //   sidebar_tilte: null,
        // },
        {
          text: "favourite",
        },
      ],
      items: [],
      items1: [],
      loading: true,
      isshowarticleView: false,
      showSound: false,
      soundItems: [],
      soundFavItems: [],
      showMp3Player: false,
      contentName: "",
      contentFile: "",
      wordDescription: "",
      optimizeLink: "",
      link: "",
      pdfUrl: "",
      favFlag: "",
      Videodialog: false,
      Videolink: "",
      contentCheck: 0,
      dialog: false,
      // Sound data
      Addfav: false,
      audioDescription: "",
      audioName: "",
      audioImg: "",
      contentId: 0,
      activeItem: null,
      //video dialog data
      AddVideofav: false,
      videoDescription: "",
      videoName: "",
      //contentId: 0,
      video_File: "",
      courseID: 0,
      //...................
      videoPlayerDialog: false,
      showVideoDialog: false,
      hasData: false,
      lastWatchedTime: "",
      viewerFlag: false,
      memberID: "",
    };
  },
  mounted() {
    this.getFavourite();
  },
  methods: {
    goarticleDetail(item) {
      console.log("item", item);
      let self = this;
      if (item.contentCheck === 1) {
        this.activeItem = item.id;
        this.$router.push({
          path: "/Library/_subLibrary/_path/_article/articleView",
          query: { article_id: item.content_ID },
        });
      } else if (item.contentCheck === 2) {
        self.activeItem = item.id;
        self.showSound = true;
        self.audioImg = item.pictureBackground;
        self.audioName = item.content_Name;
        self.audioDescription = item.wordDescription;
        self.contentId = item.id;
        self.dialog = true;
        self.contentFile = item.content_File;
        self.currentminutes = item.currentminutes;
        self.viewerFlag = item.viewerFlag;
        self.$refs.soundPlay.getFavorite(item.favFlag);
        self.$refs.soundPlay.GetCommentsForSound(item.id);
      } else if (item.contentCheck === 4) {
        self.activeItem = item.id;
        self.$router.push({
          path: "/LessonLearn/" + item.content_Name.split("?").join(""),
          query: { lessonlearn_id: item.id },
        });
      } else {
        self.activeItem = item.id;
        self.showVideoDialog = true;
        self.video_File = item.content_File;
        self.contentId = item.content_ID;
        self.videoName = item.content_Name;
        self.courseID = -1;
        self.viewerFlag = item.viewerFlag;
        self.memberID = item.memberID;
        self.$refs.videoPlay.GetCommentsForVideo(item.content_ID);
        self.$refs.videoPlay.getFavorite(item.favFlag);
      }
    },
    show_sound() {
      this.showSound = true;
    },
    async getFavourite() {
      let self = this;
      await axios
        .get(
          `${self.web_urlV6}Contents/GetFavoriteMobile?UserID=` +
            parseInt(localStorage.getItem("UserID"))
        )
        .then(function (res) {
          console.log("res.data.data", res.data.data);
          if (res.data.data.length > 0) {
            self.items = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
            }));
            self.loading = false;
            self.hasData = true;
          } else {
            self.loading = false;
            self.hasData = false;
          }
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.test {
  display: flex;
  justify-content: center;
}

.border1 {
  height: 1px;
  width: 95%;
  background-color: #00000027;
}
.lessonimg {
  width: 192px !important;
  height: 130px !important;
  border-radius: 12px;
}
.lessonTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.lessonDes {
  font-size: 14px;
  opacity: 0.6;
}
.lessonIcon {
  margin-left: 20px;
  color: #4fb14e;
  font-size: 40px;
  top: 10px;
  position: absolute;
}
::v-deep .audioPhoto {
  object-fit: cover;
  /* width: 280px; */
  border-radius: 16px;
}
.noarticle {
  height: 200px;
  align-items: center;
}
.nodata {
  color: #a4a4a4;
  font-size: 20px !important;
}
.loadingarticle {
  height: 250px;
}
</style>
